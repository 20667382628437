<template>
  <v-tooltip 
    :top="tooltip.top"
    :bottom="tooltip.bottom"
    :left="tooltip.left"
    :right="tooltip.right"
    :disabled="tooltip.disabled"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-show="show"
        :text="text"
        :tile="tile"
        :icon="!text&&icon&&!tile"
        :color="controller.toggle ? pressColor : color"
        :width="!text&&icon ? size : undefined"
        :height="!text&&icon ? size : undefined"
        :min-width="minWidth"
        :max-width="maxWidth"
        :min-height="minHeight"
        :max-height="maxHeight"
        :class="[contentClass, { disabled }]"
        class="m-btn-long"
        v-bind="attrs"
        v-on="on"
        @mousedown="start"
        @mouseup="cancel"
        @touchstart="start"
        @touchmove="moved"
        @mouseenter="over"
        @mouseleave="moved"
        @click.stop
      >
        <v-progress-linear
          v-if="(tile||text)&&(controller.toggle||isLoading)"
          :value="controller.progress"
          :indeterminate="isLoading"
          absolute
          bottom
          height="4"
          background-opacity=".08"
          opacity=".4"
          :color="pressColor"
          class="progress linear"
          :style="{ 'margin': '-8px -16px', '--duration': duration+'ms' }"
        />
        <v-progress-circular
          v-else-if="controller.toggle||isLoading"
          :value="controller.progress"
          :indeterminate="isLoading"
          :color="pressColor"
          :size="size"
          :background-color="backgroundColor"
          :rotate="rotate"
          background-opacity=".4"
          class="progress circular"
          :class="{ 'active': controller.toggle }"
          :style="{ '--duration': duration+'ms' }"
        />
        <v-icon
          v-if="icon!==undefined"
          style="z-index: 2"
          :small="small"
          :size="size-16"
          :left="text"
        >
          {{ icon }}
        </v-icon>
        {{ label }}
      </v-btn>
    </template>
    {{ tooltip.text }}
  </v-tooltip>
</template>

<style lang="scss">

  .progress .v-progress-circular__overlay {
    transition-property: none !important;
  }
  .progress {
    position: absolute !important;
    &.linear {
      bottom: 0 !important;
      left: 0 !important;
      width: calc(100% + 32px) !important;
      .v-progress-linear__determinate {
        // opacity: .4;
      }
    }
  }
  .m-btn-long {
    overflow: hidden !important;
  }
  .m-btn-long.disabled {
    opacity: .5;
    cursor: default;
  }

</style>

<script>
  export default {
    props: {
      show: {
        type: Boolean,
        default: true,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      icon: {
        type: String,
        default: undefined,
      },
      text: {
        type: Boolean,
        default: false,
      },
      tile: {
        type: Boolean,
        default: false,
      },
      duration: {
        type: [Number, String],
        default: 3000,
      },
      label: {
        type: String,
        default: undefined,
      },
      minWidth: {
        type: [Number, String],
        default: undefined
      },
      minHeight: {
        type: [Number, String],
        default: undefined
      },
      maxWidth: {
        type: [Number, String],
        default: undefined
      },
      maxHeight: {
        type: [Number, String],
        default: undefined
      },
      size: {
        type: [Number, String],
        default: 36,
      },
      rotate: {
        type: Number,
        default: -90,
      },
      color: {
        type: String,
        default: undefined,
      },
      pressColor: {
        type: String,
        default: 'accent',
      },
      backgroundColor: {
        type: String,
        default: 'secondary',
      },
      tooltip: {
        type: Object,
        default: () => {
          return {
            top: false,
            bottom: false,
            left: false,
            right: false,
            disabled: true,
            text: '',
          }
        },
      },
      contentClass: {
        type: String,
        default: '',
      },
      small: {
        type: Boolean,
        default: false
      }
    },

    data: () => ({
      toggle: false,
      controller: {
        toggle: false,
        timer: null,
        step: 0,
        progress: 0,
        timestamp: null,
        moved: false,
        loading: false
      },
      options: {
        tooltip: {
          top: false,
          bottom: false,
          left: false,
          right: false,
          disabled: true,
          text: '',
        }
      }
    }),

    computed: {
      tooltipConfig () {
        return Object.assign(this.options.tooltip, this.tooltip);
      },
      isLoading () {
        return this.loading && this.controller.loading;
      }
    },

    methods: {
      start () {
        if (this.disabled) return;
        this.controller.toggle = true;
        this.controller.step = this.duration / 100;
        this.controller.timestamp = Date.now();
        this.controller.timer = setTimeout(this.update, 100);
        this.controller.loading = false;
      },
      update () {
        if (this.disabled) return;
        if (this.controller.timer!=null&&this.controller.progress<100) {
          this.controller.progress += 100/this.controller.step;
          this.controller.timer = setTimeout(this.update, 100);
        }else if (this.controller.progress>=100) {
          this.controller.loading = true;
          this.$emit('press');
          this.cancel();
        }
      },
      over () {
        this.$emit('hover', true);
      },
      moved () {
        this.$emit('hover', false);
        if (!this.controller.moved) {
          this.cancel()
        }
      },
      cancel () {
        if (this.disabled) return;
        if (this.controller.toggle&&this.controller.progress<100) {
          this.$emit('cancel');
        }
        this.controller.toggle = false;
        this.controller.progress = 0;
        this.controller.timer = null;
        this.controller.timestamp = null;
        this.controller.moved = false;
      },
    },
    mounted () {
      document.addEventListener('touchend', this.cancel, false)
      document.addEventListener('mouseup', this.cancel)
    },
    beforeDestroy () {
      document.removeEventListener('touchend', this.cancel)
      document.removeEventListener('mouseup', this.cancel)
    },
  }
</script>